import React, { useContext, useState } from 'react';
import * as S from './ImageCarousel.styles';
import { Carousel } from 'react-responsive-carousel';
import FunnelContext from '../../context/FunnelContext';
import { Builder, Image } from '@builder.io/react';
import Video from './Video';

import 'react-responsive-carousel/lib/styles/carousel.css';

const ImageCarouselWrapper = props => {
  const { images, settings, styles } = props;

  const [activeVideo, setActiveVideo] = useState(-1);

  const thumbWidth = parseInt(settings?.thumbWidth ?? 80);
  const thumbsPosition = settings?.thumbsPosition || 'bottom';
  const thumbsPositionOnMobile =
    settings?.thumbsPositionOnMobile || 'bottom-mob';

  const { pageDesign } = useContext(FunnelContext);

  const renderThumbs = () => {
    return images.map((image, index) => {
      const imageAlt = image.alt ? image.alt : `Carousel Thumb ${index}`;
      const videoClass = image?.video ? `is-video thumbnail` : ``;

      return (
        <div
          key={index}
          className={`${thumbsPosition} ${videoClass} ${thumbsPositionOnMobile}`}
        >
          <img
            src={`${image.image}?width=${thumbWidth}`}
            alt={imageAlt}
            width={thumbWidth}
            height="100%"
          />
        </div>
      );
    });
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const defStyle = {
      width: '8px',
      height: '8px',
      margin: '8px 8px',
      borderRadius: '50%',
      background: '#ccd2e3',
      cursor: 'pointer'
    };
    const style = isSelected
      ? { ...defStyle, background: '#001c72' }
      : { ...defStyle };
    return (
      <div
        style={style}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
        className={`indicator ${isSelected ? `active` : ``}`}
      />
    );
  };

  const playVideoClickHandler = (isVideo, index) => {
    if (Builder.isEditing) return;
    if (activeVideo === -1) {
      setActiveVideo(index);
    } else {
      setActiveVideo(-1);
    }
  };

  return (
    <S.Container
      pageDesign={pageDesign}
      arrowSettings={settings?.arrowSettings}
      thumbsPosition={thumbsPosition}
      thumbsPositionOnMobile={thumbsPositionOnMobile}
      thumbsHideOnMobile={settings?.thumbsHideOnMobile}
      indicatorsHideOnDesktop={settings?.indicatorsHideOnDesktop}
      styles={styles}
    >
      <Carousel
        showStatus={settings?.showStatus ?? false}
        thumbWidth={thumbWidth}
        swipeScrollTolerance={settings?.swipeScrollTolerance ?? 5}
        autoPlay={settings?.autoPlay ?? false}
        centerMode={settings?.centerMode ?? false}
        dynamicHeight={settings?.dynamicHeight ?? false}
        emulateTouch={settings?.emulateTouch ?? false}
        infiniteLoop={settings?.infiniteLoop ?? false}
        interval={settings?.interval ?? 3000}
        preventMovementUntilSwipeScrollTolerance={
          settings?.preventMovementUntilSwipeScrollTolerance ?? true
        }
        stopOnHover={settings?.stopOnHover ?? false}
        swipeable={settings?.swipeable ?? true}
        useKeyboardArrows={settings?.useKeyboardArrows ?? false}
        selectedItem={settings?.selectedItem ?? 0}
        showArrows={settings?.showArrows ?? true}
        showIndicators={settings?.showIndicators ?? true}
        showThumbs={settings?.showThumbs ?? true}
        ariaLabel={settings?.ariaLabel ?? ''}
        axis={settings?.axis ?? 'horizontal'}
        transitionTime={settings?.transitionTime ?? 500}
        renderThumbs={renderThumbs}
        renderIndicator={renderIndicator}
      >
        {images.map((image, index) => {
          const imageAlt = image.alt ? image.alt : `Carousel ${index}`;
          const videoClass = image?.video ? `is-video` : ``;

          return (
            <React.Fragment key={index}>
              <div
                key={index}
                className={videoClass}
                onClick={() =>
                  image?.video && playVideoClickHandler(image?.video, index)
                }
                role="button"
                aria-hidden="true"
              >
                {image?.builderImage ? (
                  <Image
                    image={`${image.image}?format=webp`}
                    loading={image?.lazyLoad ? 'lazy' : undefined}
                    alt={imageAlt}
                    width={390}
                    height={390}
                    style={{ width: '100%', height: 'auto' }}
                  />
                ) : (
                  <img
                    src={`${image.image}?format=webp`}
                    loading={image?.lazyLoad ? 'lazy' : undefined}
                    alt={imageAlt ? imageAlt : `Carousel ${index}`}
                    width={390}
                    height={390}
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
              </div>
            </React.Fragment>
          );
        })}
      </Carousel>
      {activeVideo !== -1 ? (
        <Video close={setActiveVideo} {...images[activeVideo]?.videoProps} />
      ) : null}
    </S.Container>
  );
};

export default ImageCarouselWrapper;
