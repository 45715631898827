import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
`;

export const Container = styled.div`
  z-index: 9999;
  background: linear-gradient(180deg, #ffffff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  border: 1px solid #ccd2e3;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  position: relative;
  margin: auto;
  width: 70%;
  max-width: 1150px;

  @media (max-width: 500px) {
    width: 95%;
    padding: 5px;
  }

  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : ``)}
`;

export const CloseButton = styled.div`
  z-index: 999;
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  top: -15px;
  cursor: pointer;
  font-size: 30px;
  font-family: Neuzeit Grotesk;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bc2e3e;

  & > span {
    display: block;
    margin: auto;
  }
`;

export const CartX = styled.img``;
