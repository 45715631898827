import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import * as S from './Video.styles';
import Vidalytics from '../Video2/Vidalytics';

const Video = ({ close, vidalyticsId, vidalyticsUrl, maxWidth }) => {
  const [isSecondVideo, setIsSecondVideo] = useState(false);

  useEffect(() => {
    if (window.Vidalytics) {
      setIsSecondVideo(true);
    }
  }, []);

  return (
    <Modal>
      <S.Wrapper>
        <S.Container maxWidth={maxWidth}>
          <S.CloseButton onClick={() => close(-1)}>
            <S.CartX src="/images/cartx.svg" alt="close" />
          </S.CloseButton>
          <Vidalytics
            vidalyticsId={vidalyticsId}
            vidalyticsUrl={vidalyticsUrl}
            secondVideo={isSecondVideo}
          />
        </S.Container>
      </S.Wrapper>
    </Modal>
  );
};

export default Video;
